import { Atom, SHARED_UTILS, log } from ":mods";
import { updateProfileInputs } from "../../forms";
import {
  ACountrie,
  OTPProps,
  ProfileDetailsProps,
  ProfileUpdateViewProps,
  RegionsProps,
  VerifyEmailProps,
} from "../../models";
import { Show, createEffect, createSignal, onCleanup, untrack } from "solid-js";
import { GENDERS_LIST } from "../../const";
import { OTP } from "./otp";
import {
  getRegions,
  getProfileDetails,
  verifyEmailOtp,
  updateProfileDetials,
  updateProfileImage,
  verifyEmail,
  getCountries,
} from "../../apis";

// FIXME: @AhmedKaramDev keeps calling api on loop why??
export function Profile({ $, ...props }: ProfileUpdateViewProps) {
  const [profileDetails, setProfileDetails] = createSignal({} as ProfileDetailsProps);
  let profileRef = null;
  const alert = $.actions.pushToast;
  const { user_id } = $.actions.getTokenDetails() || {};
  const defaultDate = SHARED_UTILS.subtractYears(new Date(), 18);
  const [disableEmail, setDisableEmail] = createSignal(true);
  const [showOTP, setShowOTP] = createSignal(false);
  const [emailStatus, setEmailStatus] = createSignal("Verifed");
  const [bod, setBOD] = createSignal(
    defaultDate.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" })
  );
  const [countries_or_regions_list, set_countries_or_regions_list] = createSignal([]);
  const [countries_or_regions_data, setRegionsData] = createSignal([]);
  const [openCalendar, setOpenCalendar] = createSignal(false);
  const [showProfileEdit, setShowProfileEdit] = createSignal(false);
  const FormInputs = updateProfileInputs();
  const [$show_countris_list, set_show_countris_list] = createSignal(false); // default to regions
  $.actions.updateSideNavStatus("hide");
  $.actions.setLayout({ title: "" });

  onCleanup(() => {
    $.actions.updateSideNavStatus("show");
  });

  FormInputs.Actions.onChange((inputs) => {
    const v = inputs.getValuesSnakecase();
    // console.log("inputs are :: ", v);
    FormInputs.Email.control.setValue(v?.email);
    if (v?.dob) {
      const dob = new Date(v?.dob).toLocaleDateString("en-gb", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      setBOD(dob);
      FormInputs.DOB.control.setValue(dob as any);
    }
    const gender = GENDERS_LIST.find((g) => g == v?.gender) ?? "Unknown Gender";
    FormInputs.Gender.control.setValue(gender);
  });

  createEffect(() => {
    untrack(() => {
      getProfileDetails(user_id.toString()).then(({ data: profile_details }) => {
        if (!profile_details) {
          return;
        }
        setProfileDetails(profile_details);
        FormInputs.Actions.update(profile_details as any, null, ["Region"]);
        const country_id = profile_details?.country;
        const region_id = profile_details?.region;
        getCountries().then(async (countries: ACountrie[]) => {
          // console.log("countries :: ", countries);
          const sa_countries = countries.filter((c) => c?.iso2?.toUpperCase() === "SA");
          // view countries list
          if (country_id !== null) {
            const countries_names = countries.map((c) => c?.name);
            set_countries_or_regions_list(countries_names);
            setRegionsData(countries);
            const active_country = countries.find((c) => c.id === country_id);
            FormInputs.Country.control.setValue(active_country?.name);
            set_show_countris_list(true);
            return;
          }
          if (!sa_countries) {
            log.all.error("no saudi arabia countries");
            return;
          }
          const saudi = sa_countries[0];
          return getRegions(saudi.id as any).then(({ data: regions }) => {
            // console.log("regions :: ", response);
            // setRegionsList(response?.data);
            // setRegionsOptions(response?.data?.map((r) => r?.name));
            set_countries_or_regions_list(regions?.map((region: RegionsProps) => region.name));
            setRegionsData(regions);
            const active_region = regions.find((region) => region.id === region_id);
            FormInputs.Region.control.setValue(active_region?.name);
          });
        });
        // if (!!r) {
        //   getRegions(`${r}`).then(({ data: regions }) => {
        //   });
        // }
      });
    });
  });

  const onBODSelected = (date: Date) => {
    setBOD(date.toLocaleDateString("en-gb", { year: "numeric", month: "long", day: "numeric" }));
    FormInputs.DOB.control.setValue(bod());
  };
  const selectProfileImage = () => {
    profileRef?.click();
  };
  const modifyEmail = () => {
    setDisableEmail(false);
  };
  const onVerfiyEmail = async () => {
    const body: VerifyEmailProps = {
      email: FormInputs.Email.control.value,
    };
    await verifyEmail(body)
      .then(({ message }) => {
        if (message == "success") setShowOTP(true);
      })
      .catch(({ response }) => {
        if (response?.data?.error?.[0]) FormInputs.Email.control.setErrors({ [response?.data?.error?.[0]]: true });
        setEmailStatus();
      });
  };
  const onOTPVerify = async (body: OTPProps) => {
    body["email"] = FormInputs.Email.control.value;
    await verifyEmailOtp(body)
      .then(() => {
        alert({ type: "success", message: `Email has been changed!` });
        setShowOTP(false);
        setEmailStatus("Updated And Verified!");
        setDisableEmail(true);
      })
      .catch(() => {
        setShowOTP(false);
        setEmailStatus("Verified failed !");
        FormInputs.Email.control.setErrors({ ["Verified failed !"]: true });
      });
  };
  const onRemoveImage = () => {
    const body = { ...profileDetails() };
    body["profile_image"] = null;
    updateProfileDetials(user_id.toString(), body).then(() => {
      alert({ type: "success", message: `Image Removed Successfully` });
      FormInputs.ProfileImage.control.setValue(undefined);
      setProfileDetails((s) => ({ ...s, profile_image: null }));
      // $.actions.navigate(location.pathname);
    });
  };

  const onUploadProfileImage = () => {
    if (!!FormInputs.ProfileImage.control.value) {
      const formData = new FormData();
      const file = FormInputs.ProfileImage.control.value[0];
      formData.append("profile_image", file);
      console.log("profile_image", file);
      updateProfileImage(formData).then(() => {
        alert({ type: "success", message: `Profile Image Updated Successfully` });
        FormInputs.Actions.helpers.getFileString(file, (image) => {
          setProfileDetails((s) => ({ ...s, profile_image: image as string }));
        });
        // FormInputs.ProfileImage.control.set
        // $.actions.navigate(location.pathname);
      });
    } else {
      alert({ type: "info", message: "Please click on the image and upload image first!" });
      // alert({ type: "error", message: "Profile Image Upload Error!" });
    }
  };
  const onCountryCodeSelected = (country: string) => {
    FormInputs.CountryCode.control.setValue(country as string);
  };
  const onDiscard = () => {
    $.actions.navigateDelta(-1);
  };
  const onSubmit = async () => {
    const body = FormInputs.Actions.getValuesSnakecase() as unknown as ProfileDetailsProps;
    const has_country = $show_countris_list();
    if (!has_country) {
      body.country = profileDetails().country;
      body.region =
        countries_or_regions_data().find((region) => region?.name == body.region)?.id ?? profileDetails().region;
    } else {
      body.country =
        countries_or_regions_data().find((country) => country?.name == body.country)?.id ?? profileDetails().country;
      // body.country = null;
      body.region = profileDetails().region;
    }
    body.dob = new Date(body.dob).toLocaleDateString("en-ca");
    body.gender = body.gender?.toLowerCase();
    delete body.profile_image;
    await updateProfileDetials(user_id.toString(), body)
      .then(() => {
        alert({ type: "success", message: `Profile Updated Successfully` });
      })
      .catch(() => {
        alert({ type: "error", message: `Profile Update Failed` });
      });
  };

  return (
    <div class="flex flex-col justify-center items-center gap-20px">
      <h1 class="">My Profile</h1>
      <section class="flex items-center gap-20px">
        <FormInputs.ProfileImage.InputFile
          placeholder="/images/profile_image_template.png"
          accept="image/*"
          elements={{
            input(props) {
              return (
                <div
                  class="flex flex-col justify-center items-center bg#highlight text-white w-222px h-222px absolute top-0 opacity-60 cursor-pointer"
                  onclick={props.onInputClicked}
                >
                  <i class="icon-local:edit w-22px h-22px" />
                  <span class="text-16px">{props.drag ? "Drop Files" : "Edit"}</span>
                </div>
              );
            },
            input_text: ({ drag, file }) =>
              !file ? (!drag ? "Edit" : "drop files") : drag ? "update file" : file.name,
            viewer({ placeholder }) {
              return <img src={placeholder} class="w-full h-full " />;
            },
          }}
          classes={{
            container: "w-222px h-222px rounded-full border-dashed border-2 border-red",
            input: "bg#highlight text-white opacity-60",
          }}
        />

        <div class="flex flex-col gap-20px">
          <Atom.Form.Button
            controls={{}}
            statusValid={profileDetails()?.profile_image ? "Update Avatar Image" : "Add Image"}
            onclick={onUploadProfileImage}
            class="bg#p h-44px text-white text-16px py-10px px-15px font-700 rounded-3px cursor-pointer"
          />
          {profileDetails()?.profile_image && (
            <Atom.Buttons.Button
              text="Remove Image"
              theme="light"
              outlined
              cls="!bg-inherit !text#p text-16px py-10px px-15px font-700 !w-149px whitespace-nowrap"
              onClick={onRemoveImage}
            />
          )}
        </div>
      </section>

      <form class="text-16px mt-40px">
        <div class="flex flex-col gap-10px">
          <FormInputs.FirstName.Label title="First Name">
            <FormInputs.FirstName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.FirstName.Label>
          <FormInputs.FirstName.Input
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>

        <div class="flex flex-col gap-10px">
          <FormInputs.LastName.Label title="Last Name">
            <FormInputs.LastName.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.LastName.Label>
          <FormInputs.LastName.Input
            type="text"
            class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
            placeholder=""
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.Email.Label title="Email"></FormInputs.Email.Label>
          <div class="flex gap-10px items-center">
            <FormInputs.Email.Input
              type="text"
              class={`border-2 border#p border-solid text-0.8rem px-2.5 py-2 w-491px h-50px ${
                disableEmail() && "bg#n-50 cursor-not-allowed"
              }`}
              placeholder="email@email.com"
              readOnly={disableEmail()}
            />
            <Show when={disableEmail()}>
              <div
                onClick={modifyEmail}
                class="w-30% flex justify-center items-center font-700 text-16px py-10px px-15px border-2 border#p border-solid cursor-pointer"
              >
                <span>Modify Email</span>
              </div>
            </Show>
            <Show when={!disableEmail()}>
              <Atom.Form.Button
                controls={{}}
                onclick={onVerfiyEmail}
                statusValid="Verify Email"
                statusInvalid="Verify Email"
                class="w-30% h-44px flex justify-center items-center bg#p text-white font-700 text-16px py-10px px-15px border-2 border#p border-solid cursor-pointer"
              />
            </Show>
            <Show when={showOTP()}>
              <OTP onOTPSuccess={onOTPVerify} />
            </Show>
          </div>
          <FormInputs.Email.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          <span class="text#n-200">{emailStatus()}</span>
        </div>

        <div class="flex flex-col gap-10px mb-2">
          <FormInputs.Gender.Label title="Gender">
            <FormInputs.Gender.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.Gender.Label>
          <FormInputs.Gender.Select
            options={GENDERS_LIST}
            class="form-select border-2 border#p bg#paper border-solid text-14px !py-2 w-full h-45px"
            placeholder=""
            optionGroupClass={"!text#p"}
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.DOB.Label title="Date of birth">
            <FormInputs.DOB.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.DOB.Label>

          <FormInputs.DOB.Input
            type="text"
            class="text-16px bg-inherit border-2 border#p border-solid px-2.5 py-2 mb-2 w-630px h-50px cursor-pointer"
            placeholder=""
            readOnly
            onClick={() => setOpenCalendar(openCalendar() ? false : true)}
          />
          <Atom.External.DatePicker
            show={openCalendar()}
            value={new Date(bod())}
            initialDate={defaultDate}
            onDateSelected={onBODSelected}
          />
        </div>
        <div class="flex flex-col gap-10px">
          <FormInputs.MobileNo.Label title="Phone Number">
            <FormInputs.MobileNo.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
          </FormInputs.MobileNo.Label>
          <FormInputs.MobileNo.PhoneInput
            onCountryCodeSelected={onCountryCodeSelected}
            countryCodeControl={FormInputs.CountryCode.control}
            class="border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 w-630px h-50px"
          />
        </div>

        {$show_countris_list() ? (
          <div class="flex flex-col gap-10px">
            <FormInputs.Country.Label title="Country">
              <FormInputs.Country.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.Country.Label>
            <FormInputs.Country.Select
              options={countries_or_regions_list()}
              class="form-select border-2 border#p bg#paper border-solid text-14px !py-2 w-full h-45px"
              placeholder=""
              optionGroupClass={"!text#p"}
            />
          </div>
        ) : (
          <div class="flex flex-col gap-10px">
            <FormInputs.Region.Label title="Region">
              <FormInputs.Region.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
            </FormInputs.Region.Label>
            <FormInputs.Region.Select
              options={countries_or_regions_list()}
              class="form-select border-2 border#p bg#paper border-solid text-14px !py-2 w-full h-45px"
              placeholder=""
              optionGroupClass={"!text#p"}
            />
          </div>
        )}
        <div class="flex gap-20px justify-end mt-40px">
          <FormInputs.Actions.Button
            statusValid="Discard Changes"
            onClick={onDiscard}
            class="!w-165px text-16px font-700 bg-inherit border border-2 border-solid"
          />
          <FormInputs.Actions.Button
            onclick={onSubmit}
            class={"!w-161px h-44px text-16px font-700 text-white !bg#p cursor-pointer"}
            statusValid="Update Changes"
            statusInvalid="Update Changes"
          />
        </div>
      </form>
    </div>
  );
}
